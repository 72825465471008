<template>
  <StallionDialog
    v-model="dialog"
    title="Upload Products"
    persistent
    :width="800"
  >
    <template #content>
      <v-row>
        <v-col cols="12">
          <h3>Upload Products</h3>
          <p>
            You can use the products template to upload all your products.
          </p>

          <a
            download
            class="import-list"
            href="/templates/products/Products_Template.csv"
          >
            Products Template
          </a>
        </v-col>
        <v-col cols="12">
          <v-file-input
            v-model="file"
            label="Products CSV"
            accept=".csv"
            outlined
            dense
            @change="setFile"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        />
      </v-row>
    </template>

    <template #actions>
      <v-spacer />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="uploadDisabled || uploadLoading"
            :loading="uploadLoading"
            v-on="on"
            @click="uploadFile()"
          >
            <v-icon small>
              mdi-upload
            </v-icon>Upload
          </v-btn>
        </template>
        <span>Submit your file to begin the import</span>
      </v-tooltip>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      file: null,
      uploadDisabled: true,
      uploadLoading: false,
      uploadMessage: 'Importing products'
    };
  },
  methods: {
    setFile() {
      if (this.file) {
        this.uploadDisabled = false;
      } else {
        this.uploadDisabled = true;
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('import_file', this.file);
      this.uploadLoading = true;

      this.$http
        .post('/products/upload', formData)
        .then((response) => {
          console.log(response);

          if (response.data.success) {
            const importedProducts = response.data.products.imported || [];
            const skippedProducts = response.data.products.skipped || [];

            const importedProductCount = importedProducts.length;
            const skippedProductCount = skippedProducts.length;

            this.successMessage('Products successfully imported! Imported: ' + importedProductCount + ', Skipped: ' + skippedProductCount);
            this.$emit('update-table');
            this.dialog = false;
            this.$emit('update-products');
          }
        })
        .catch((err) => {
          const status = err.response?.status;

          if (status === 422) {
            this.errorMessage(err.response.data.errors);
          } else if (status === 403) {
            this.errorMessage('This action is unauthorized.');
          } else {
            console.error('Unexpected error:', err);
            this.errorMessage('An unexpected error occurred. Please try again.');
          }
        })
        .finally(() => {
          this.file = null;
          this.uploadDisabled = true;
          this.uploadLoading = false;
        });

    },
    startNew() {
      this.dialog = true;
    }
  }
};
</script>
