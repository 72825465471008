<template>
  <v-row dense>
    <!-- Display CARM agreement info if completed -->
    <v-col
      v-if="user.carm_agreement && user.carm_agreement.agree"
      cols="12"
    >
      <v-alert type="success">
        The user has completed their CARM agreement on <strong>{{ user.carm_agreement.carm_completion_date }}</strong>.

        <!-- Button to unset CARM -->
        <v-row>
          <v-col
            class="text-left"
            style="margin-top:1em"
          >
            <v-btn
              outlined
              @click="confirmUnsetCarm()"
            >
              Unset CARM Agreement
            </v-btn>
          </v-col>
        </v-row>

        <!-- Confirmation Dialog -->
        <v-dialog
          v-model="showConfirmDialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Confirm Action
            </v-card-title>
            <v-card-text>
              Are you sure you want to unset the CARM agreement? This action will delete the CARM agreement.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cancelUnsetCarm"
              >
                Cancel
              </v-btn>
              <v-btn
                color="red"
                text
                :loading="unsetLoading"
                @click="unsetCarmAgreement"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-alert>
    </v-col>

    <v-col
      v-else
      cols="12"
    >
      <v-alert type="warning">
        The user has not yet completed their CARM agreement.
      </v-alert>
    </v-col>

    <!-- Importer Number -->
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="user.importer_number"
        v-validate="'integer|min:9|max:9'"
        v-mask="'#########'"
        dense
        outlined
        :error-messages="errs.collect('importer_number')"
        label="Importer Number"
        data-vv-name="importer_number"
        suffix="RM0001"
      />
    </v-col>

    <!-- WLR Code -->
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="user.wlr_imp_code"
        dense
        outlined
        :error-messages="errs.collect('wlr_imp_code')"
        label="WLR Code"
        data-vv-name="wlr_imp_code"
      />
    </v-col>

    <!-- Has POA -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.return_agree"
        outlined
        dense
        label="Has POA"
      />
    </v-col>

    <!-- Returns Auto Request -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="auto_request"
        outlined
        dense
        label="Returns Auto Request"
      />
    </v-col>

    <!-- Returns Autocomplete -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.return_autocomplete"
        outlined
        dense
        label="Returns Autocomplete"
      />
    </v-col>

    <!-- Returns Auto-Dispose -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.auto_dispose_returns"
        outlined
        dense
        label="Returns Auto-Dispose"
      />
    </v-col>

    <!-- Carm checkbox -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.carm_agreement.agree"
        outlined
        dense
        label="CARM Agreement"
        @change="handleCarmAgreementChange"
      />
    </v-col>

    <v-col cols="12">
      <h4>Return Description</h4>
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="user.returns_default_contents"
        placeholder="Ex: Phone Cases"
        label="Contents"
        data-vv-name="contents"
        dense
        outlined
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="user.returns_default_value"
        v-validate="'decimal|min_value:1'"
        placeholder="Ex: 10.00"
        label="Value"
        :error-messages="errs.collect('value')"
        data-vv-name="value"
        dense
        outlined
      />
    </v-col>

    <v-col cols="12">
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="updateReturnSettings"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      auto_request: 0,
      unsetLoading: false,
      showConfirmDialog: false,
      previousCarmAgree: null,
    };
  },
  watch: {
    'user.carm_agreement.agree': {
      immediate: true,
      handler(value) {
        if (typeof value == 'undefined') {
          this.user.carm_agreement = {
            agree: false
          };
        }
      },
    },
  },
  mounted() {
    const setting = this.user.settings.find(
      (s) => s.key == 'return_auto_request'
    );
    this.auto_request = setting ? setting.value : 0;
  },
  methods: {
    handleCarmAgreementChange() {
      if (this.user.carm_agreement) {
        if(this.user.carm_agreement.agree) {
          this.setCarmAgreement();
        } else {
          this.unsetCarmAgreement();
        }
      } else {
        this.previousCarmAgree = true; // Store the previous value (checked)
        this.confirmUnsetCarm();
      }
    },
    cancelUnsetCarm() {
      this.user.carm_agree = this.previousCarmAgree; // Reset the checkbox to its previous state
      this.showConfirmDialog = false; // Close the dialog
    },
    confirmUnsetCarm() {
      // Show the confirmation dialog
      this.showConfirmDialog = true;
    },
    async setCarmAgreement() {
      // Set the carm agreement to completed.
      this.$http
        .post(`/admin/users/${this.user.id}/set-carm`)
        .then((response) => {
          if (response.data.success) {
            this.user.carm_agreement = response.data.carm_agreement;
            this.$emit('user-updated', response);
            this.$notify({
              group: 'main',
              title: 'CARM Agreement Set',
              text: "The user's CARM agreement has been successfully set.",
              type: 'success',
              duration: 3000
            });
          } else {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: 'An error occurred while setting the CARM agreement.',
              type: 'error',
              duration: 3000
            });
          }
        });
    },
    async unsetCarmAgreement() {
      this.unsetLoading = true;

      // Close the confirmation dialog
      this.showConfirmDialog = false;

      this.$http
        .post(`/admin/users/${this.user.id}/unset-carm`)
        .then((response) => {
          if (response.data.success) {
            this.user.carm_agreement = null;
            this.$emit('user-updated', response);
            this.$notify({
              group: 'main',
              title: 'CARM Agreement Unset',
              text: "The user's CARM agreement has been successfully unset.",
              type: 'success',
              duration: 3000
            });
          } else {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: 'An error occurred while unsetting the CARM agreement.',
              type: 'error',
              duration: 3000
            });
          }
        })
        .finally(() => {
          this.unsetLoading = false;
        });
    },
    async updateReturnSettings() {
      const valid = await this.$validator.validate();
      if (valid) {
        this.loading = true;
        const params = {
          return_contents: this.user.returns_default_contents,
          return_value: this.user.returns_default_value,
          importer_number: this.user.importer_number,
          wlr_imp_code: this.user.wlr_imp_code,
          return_agree: this.user.return_agree,
          auto_request: this.auto_request,
          return_autocomplete: this.user.return_autocomplete,
          auto_dispose_returns: this.user.auto_dispose_returns
        };
        this.$http
          .post(`/admin/users/${this.user.id}/update-return-settings`, params)
          .then((response) => response.data)
          .then((response) => {
            this.$emit('user-updated', response);
            this.$notify({
              group: 'main',
              title: 'Settings Updated',
              text: "The user's return settings have been updated successfully",
              type: 'success',
              duration: 3000
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
