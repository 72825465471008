<template>
  <div v-if="shipment && shipment.package_type_id">
    <div class="d-flex flex-row justify-space-between">
      <h4 v-if="showHeader">
        Package
      </h4>
      <!--can from mixin.js-->
      <span
        v-if="
          shipment.status_id == 3 &&
            shipment.package_type_id !== null &&
            can('CLIENT.SHIPPING')
        "
      >
        <a
          class="float-right font-weight-bold"
          href="javascript:void(0)"
          @click="editShipment(shipment, 'package')"
        >Edit</a>
      </span>
    </div>

    <div class="d-flex flex-row justify-space-between">
      <div class="shipment-title">
        Package Type
      </div>
      <div v-html="$options.filters.package_type(shipment.package_type_id)" />
    </div>

    <div
      v-if="shipment.length && shipment.width && shipment.height"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Dimensions
      </div>
      <div>
        {{ shipment.length }} x {{ shipment.width }} x
        {{ shipment.height }}
        {{ shipment.size_unit }}
      </div>
    </div>

    <div
      v-if="shipment.weight"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Weight
      </div>
      <div>{{ shipment.weight }} {{ shipment.weight_unit }}</div>
    </div>

    <div v-if="shipment.items">
      <div
        v-for="item in shipment.items"
        :key="item.id"
      >
        <v-divider class="my-1" />
        <!-- <div class="d-flex flex-row justify-space-between">
        <h6>Item {{ index + 1 }}</h6>
      </div> -->

        <!-- <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">{{ item.title }}</div>
        <div></div>
      </div> -->

        <div
          v-if="item.sku"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            SKU
          </div>
          <div>{{ item.sku }}</div>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Quantity
          </div>
          <div>{{ item.quantity }}</div>
        </div>

       

        <!-- <div
        v-if="item.weight"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Weight
        </div>
        <div>{{ item.weight }} {{ item.weight_unit }}</div>
      </div> -->

        <div
          v-if="item.value"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Unit Value
          </div>
          <div>{{ item.value | currency }} {{ item.currency }}</div>
        </div>

        <div>
          <div class="shipment-title">
            Description
          </div>
          <div> {{ item.description }}</div>
        </div>
        <div
          v-if="item.country_of_origin"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Country of Origin
          </div>
          <div> {{ item.country_of_origin }}</div>
        </div>
        <div
          v-if="item.hs_code"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            HS Code
          </div>
          <div> {{ item.hs_code }}</div>
        </div>
        <div
          v-if="item.customs_description"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Customs Description
          </div>
          <div> {{ item.customs_description }}</div>
        </div>
      </div>

      <v-divider class="mt-1 mb-3" />
      <div
       
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Total Value
        </div>
        <div>{{ getCombinedValue(shipment?.items) }}  </div>
      </div>
    </div>
   
    <div v-else-if="shipment.package_contents">
      <div
        v-if="shipment.value"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Value
        </div>
        <div>{{ shipment.value | currency }} {{ shipment.currency }}</div>
      </div>

      <div
        v-if="shipment.value"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Contents
        </div>
        <div>
          {{ shipment.value | currency }} {{ shipment.package_contents }}
        </div>
      </div>
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>
