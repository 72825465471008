<template>
  <v-container
    class="py-5 px-8"
    fluid
    style="background-color: #fcfcfc"
  >
    <v-container>
      <StallionDialog
        v-if="!$auth.user?.return_agree"
        v-model="show_confirm"
        title="Auto Disposal Warning"
        :width="500"
      >
        <template #content>
          <v-row dense>
            <v-col
              cols="12"
              text-center
              mt-6r
            >
              <span v-if="$auth.user?.poa_sign_by_date == null">
                <h4 class="mt-5">
                  In order to bring back your returns, we require a valid
                  importer number.
                </h4>
                <v-col
                  cols="12"
                  text-center
                  mt-3
                >
                  <v-alert
                    text
                    type="warning"
                  >
                    You have 14 days to sign the returns agreement otherwise
                    all future return shipments will be automatically disposed
                    upon reception.
                  </v-alert>
                </v-col>
              </span>
              <span v-else-if="!deadline_expired">
                <h4 class="font-weight-regular">
                  In order to bring back your returns, we require a valid
                  importer number.
                </h4>
                <v-col
                  cols="12"
                  text-center
                  mt-3
                >
                  <v-alert
                    text
                    type="warning"
                  >
                    You have {{ getTimeDifference }}
                    {{ getTimeDifference | pluralize('day') }} to sign the
                    returns agreement. Otherwise, all future return shipments
                    will be automatically disposed upon reception.
                  </v-alert>
                </v-col>
              </span>
              <span v-else>
                <h4>
                  Auto Dispose has been enabled on your account. Please sign
                  the agreement to be able to request new returns we receive
                  at our US facility.
                </h4>
              </span>
            </v-col>
          </v-row>

          <v-divider />
        </template>

        <template #actions>
          <v-spacer />
          <v-btn
            outlined
            color="primary"
            @click="acceptDisclaimer()"
          >
            Accept
          </v-btn>
        </template>
      </StallionDialog>
      <StallionDialog
        v-model="show_deny"
        title="Auto Disposal Acceptance"
        :width="500"
      >
        <template #content>
          <v-row dense>
            <v-col
              cols="12"
              text-center
              mt-6r
            >
              <span>
                <h4>
                  Auto Dispose will be enabled on your account. Please sign
                  the agreement to be able to request new returns we receive
                  at our US facility. Click on deny to continue.
                </h4>
              </span>
            </v-col>
          </v-row>
        </template>

        <template #actions>
          <v-spacer />
          <v-btn
            outlined
            color="primary"
            @click="denyDisclaimer()"
          >
            Deny
          </v-btn>
        </template>
      </StallionDialog>
      <v-card outlined>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <h1>Returns Agreement</h1>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="secondary"
                  @click="remindMeLater()"
                >
                  Remind Me Later
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="warning"
                  @click="deny()"
                >
                  Deny
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Terms and Conditions</h2>
                <v-card
                  outlined
                  class="pa-5"
                >
                  <ul>
                    <li>
                      Items are subject to be opened by CBSA or by
                      StallionExpress employees for any necessary inspection
                    </li>
                    <li>
                      Stallion Express is not liable for any missing items
                      within box/parcel/envelope, etc.
                    </li>
                    <li>
                      All return requests are subject to a $4.99 (plus HST)
                      charged directly to your StallionExpress account upon
                      request
                    </li>
                    <li>
                      Requests to dispose of item will be $2.50 (plus HST) per
                      box/parcel/envelope, charged directly to your Stallion
                      Express account
                    </li>
                    <li>
                      Canadian and US Returns must be claimed by 30 days upon initial scan and process of the return in your account otherwise the return will be disposed for $2.50 (plus HST) for US returns only
                    </li>
                    <li>
                      Please ensure when entering the value of your items you
                      put in the retail value of the item.
                    </li>
                    <li>
                      Only items initially shipped through Stallion are eligible
                      for Returns.
                    </li>
                    <li>
                      There is no Return service for International shipments. If
                      shipments are unclaimed or undeliverable, it will be
                      disposed by the carrier at no additional cost.
                    </li>
                    <li>
                      Canadian returns can be sent back directly to your
                      business address. A return label can be created using UPS
                      directly from your account and can be sent to your
                      business address.
                    </li>
                    <li>
                      For undelivered Canadian shipments, a return fee which is
                      equivalent to the original shipping cost will be deducted
                      from your account.
                    </li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Agency Agreement to Appoint a Customs Broker</h2>
                <v-card
                  class="pa-5"
                  outlined
                >
                  <v-col cols="12">
                    We hereby appoint WILLIAM L. RUTHERFORD LIMITED.(Licenced Customs
                    Brokers under Customs Act 32) Business number 892673971, ON,
                    Canada to be my true and lawful attorney/Agent, to transact
                    business on my behalf in all matters relating to import and
                    export of goods with Canada Border Security Agency.
                  </v-col>

                  <ul>
                    <li>For release of Imported Goods</li>
                    <li>
                      Accounting and payment of duties in respect of goods
                      released under section 32 of the Customs Act
                    </li>
                    <li>
                      At customs office(s) located in ALL REGIONS of Canada
                    </li>
                    <li>
                      This authorization allows for the appointment of
                      sub-agents
                    </li>
                    <li>Processing of refunds and other adjustments</li>
                  </ul>

                  <v-col cols="12">
                    An agent is considered in law to represent the importer, in
                    such a way as to be able to affect the Importer legal
                    position. However, the Importer remains liable for any
                    transactions completed on its behalf by its agent.
                  </v-col>

                  <v-col cols="12">
                    The duties, charges or other amount paid on my behalf or to
                    my account by my agent /attorney are debt due to me/us and
                    are payable by me /us by due date.
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Account Type</h2>
                <v-card
                  class="pa-5"
                  outlined
                >
                  <v-row dense>
                    <v-col cols="12">
                      If you are registered as a business in Canada, our broker
                      and the CRA require you to have an activated importer
                      number to import new items and returns to Canada.
                      <ul class="mt-3">
                        <li style="font-weight: bold">
                          This is a business account
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Account Information</h2>
                <v-card
                  class="pa-5"
                  outlined
                >
                  <v-row>
                    <v-col cols="12">
                      <p>
                        <strong>Where's my Importer Number?</strong><br>
                        Unsure of whether or not you have an importer number?
                        <br>Call CRA at 1-800-959-5525 and speak with an agent
                        to activate your importer number or find out whether
                        it's active already.
                      </p>
                    </v-col>
                  </v-row>
                  <v-form
                    data-vv-scope="poa"
                    @submit.prevent
                  >
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="importer_number"
                          v-validate="'required|integer|min:9|max:9'"
                          v-mask="'#########'"
                          outlined
                          :error-messages="errs.collect('poa.importer_number')"
                          label="Importer Number"
                          data-vv-name="importer_number"
                          suffix="RM0001"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="business_name"
                          v-validate="'required'"
                          outlined
                          :error-messages="errs.collect('poa.business_name')"
                          label="Business Name"
                          data-vv-name="business_name"
                          @click="show = !show"
                          @input.native="show = true"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-alert
                          type="error"
                        >
                          Ensure the provided business name matches the CRA's records for the importer number to avoid delays.
                        </v-alert>
                      </v-col>
                    </v-row>
                   
                    <v-row dense>
                      <!-- EMail -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          id="email"
                          v-model="email"
                          v-validate="'required|email'"
                          outlined
                          :error-messages="errs.collect('poa.email')"
                          label="Email"
                          data-vv-name="email"
                          required
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <!-- First Name -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="first_name"
                          v-validate="'required'"
                          outlined
                          :error-messages="errs.collect('poa.first_name')"
                          label="First Name"
                          data-vv-name="first_name"
                        />
                      </v-col>

                      <!-- Last Name -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="last_name"
                          v-validate="'required'"
                          outlined
                          :error-messages="errs.collect('poa.last_name')"
                          label="Last Name"
                          data-vv-name="last_name"
                        />
                      </v-col>

                      <!-- Address1 -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address1"
                          v-validate="'required|max:50'"
                          outlined
                          :error-messages="errs.collect('poa.address1')"
                          label="Address1"
                          data-vv-name="address1"
                        />
                      </v-col>

                      <!-- Address2 -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="address2"
                          v-validate
                          outlined
                          :error-messages="errs.collect('poa.address2')"
                          label="Address2"
                          data-vv-name="address2"
                        />
                      </v-col>

                      <!-- City -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="city"
                          v-validate="'required'"
                          outlined
                          :error-messages="errs.collect('poa.city')"
                          label="City"
                          data-vv-name="city"
                        />
                      </v-col>

                      <!-- Country code -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="country_code"
                          v-validate="'required'"
                          outlined
                          value="CA"
                          :error-messages="errs.collect('poa.country_code')"
                          label="Country"
                          data-vv-name="country_code"
                          readonly
                        />
                      </v-col>

                      <!-- Province/State -->
                      <v-col
                        cols="12"
                        md="6"
                        style="padding-top: 18px"
                      >
                        <v-autocomplete
                          v-model="province_code"
                          v-validate="'required|max:2'"
                          outlined
                          :error-messages="errs.collect('poa.province_code')"
                          data-vv-name="province_code"
                          :items="provinces"
                          label="Province"
                          item-text="name"
                          item-value="code"
                          :search-input.sync="searchInput2"
                          dense
                        />
                      </v-col>

                      <!-- Postal Code -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-if="country_code == 'CA'"
                          v-model="postal_code"
                          v-mask="'A#A #A#'"
                          v-validate="{
                            required: true,
                            regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                          }"
                          outlined
                          validate-on-blur
                          :error-messages="errs.collect('poa.postal_code')"
                          label="Postal Code"
                          data-vv-name="postal_code"
                        />
                      </v-col>

                      <!-- Phone -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="phone"
                          v-mask="'###########'"
                          v-validate
                          outlined
                          :error-messages="errs.collect('poa.phone')"
                          label="Phone"
                          data-vv-name="phone"
                        />
                      </v-col>
                      <v-col cols="12">
                        <h2>Signature</h2>
                        <VueSignaturePad
                          id="signature"
                          ref="signaturePad"
                          width="100%"
                          height="200px"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-btn
                          depressed
                          small
                          @click="clear"
                        >
                          Clear
                        </v-btn>
                        <v-btn
                          depressed
                          small
                          @click="undo"
                        >
                          Undo
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        Signing this Power of Attorney document does not affect
                        your returns pricing - this document simply allows our
                        customs broker to clear your packages when we transport
                        them across the border.
                      </v-col>
                      <v-col
                        cols="12"
                        style="font-weight: bold; font-size: 14px; color: #ff1744"
                      >
                        Please note that there will be a $5 one-time fee for setting up your account with the broker. The broker may take 10 business days to verify your business number.
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-checkbox
                          v-model="agree"
                          v-validate="'required'"
                          outlined
                          :error-messages="errs.collect('poa.agree')"
                          data-vv-name="agree"
                          label="I agree to the Terms and Conditions"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-btn
                          color="primary"
                          :disabled="!agree || processing"
                          :loading="processing"
                          @click="submit2"
                        >
                          Agree
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import CONSTANTS from '@/constants';
import VueSignaturePad from 'vue-signature-pad';
export default {
  metaInfo: {
    title: "Returns Agreement | Stallion Express"
  },
  components: {
    VueSignaturePad
  },
  data() {
    return {
      name: '',
      e1: 1,
      agree: null,
      searchInput2: null,
      processing: false,

      provinces: CONSTANTS.PROVINCES,

      email: null,
      business_name: null,
      address1: null,
      first_name: null,
      last_name: null,
      address2: null,
      city: null,
      province_code: null,
      postal_code: null,
      country_code: null,
      phone: null,
      importer_number: null,
      show_confirm: false,
      show_deny: false,
      show: false
    };
  },
  computed: {
    getTimeDifference: function () {
      const date1 = new Date(this.$auth.user.poa_sign_by_date);
      const date2 = new Date();

      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    deadline_expired() {
      const date1 = new Date(this.$auth.user.poa_sign_by_date);
      const date2 = new Date();

      return date1 < date2;
    },
  },
  mounted() {
    this.email = this.$auth.user.email;
    this.name = this.$auth.user.name;
    this.address1 = this.$auth.user.address1;
    this.address2 = this.$auth.user.address2;
    this.city = this.$auth.user.city;
    this.province_code = this.$auth.user.province_code;
    this.country_code = 'CA';
    this.postal_code = this.$auth.user.postal_code;
    this.phone = this.$auth.user.phone;
    this.importer_number = this.$auth.user.importer_number;
  },

  methods: {
    remindMeLater() {
      this.show_confirm = true;
    },

    deny() {
      this.show_deny = true;
    },
    acceptDisclaimer() {
      if (this.$auth.user.poa_sign_by_date == null) {
        this.$http.post('users/acceptAutoDisposal');
      }
      this.$store.state.auth.user.poa_sign_by_date = new Date();
      this.show_confirm = false;
      this.$router.push('/dashboard');
    },
    denyDisclaimer() {
      if (this.$auth.user.return_agree == null) {
        this.$http.post('users/acceptAutoDisposal?deny=true');
        this.$store.state.auth.user.return_agree = 0;
      }
      this.show_deny = false;
      this.$router.push('/dashboard');
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    submit2() {
      this.$validator.validateAll('poa').then((result) => {
        if (result) {
          // var _this = this;

          if (this.$refs.signaturePad.isEmpty()) {
            this.errorMessage('No signature entered');
            return;
          }

          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          console.log(isEmpty);
          console.log(data);

          this.processing = true;
          this.$http
            .post('/returns/agree', {
              agree: this.agree,

              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              business_name: this.business_name,
              address1: this.address1,
              address2: this.address2,
              city: this.city,
              postal_code: this.postal_code,
              country_code: this.country_code,
              phone: this.phone,
              province_code: this.province_code,
              importer_number: this.importer_number,

              signature: data
            })
            .then((res) => {
              this.processing = false;
              if (res.data.success) {
                this.getUser().then(() => {
                  this.$router.push('/returns');
                });
                this.e1 = 2;

                this.$refs.signaturePad.openSignaturePad();
              } else {
                this.errorMessage(res.data.errors);
              }
            })
            .catch(() => {
              this.processing = false;
            });
        } else {
          this.errorMessage('Some form fields are invalid');
        }
      });
    }
  }
};
</script>

<style scoped>
#signature {
  border: solid 3px rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
.scrollable-card {
  margin-top: 20px;
  height: 300px;
  overflow-y: scroll;
  padding: 20px;
}
.header-container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .header-container {
    display: block;
  }
}
</style>
