<template>
  <v-layout justify-center>
    <v-flex
      xs12
      sm8
      md6
      mb-12
      mt-4
    >
      <v-card class="elevation-5">
        <v-col
          col-4
          text-left
          style="padding-bottom: unset"
        >
          <v-btn
            large
            text
            style="
              padding: unset;
              justify-content: start;
              align-items: start;
              align-content: start;
              height: unset;
              width: unset;
              margin: 0;
              text-align: center;
              min-width: unset;
              margin: 0 5px 0 0;
              padding: 0.5rem;
            "
            @click="setStep('address_verification')"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon> Back
          </v-btn>
        </v-col>
        <v-card-text style="margin: unset; padding-top: unset">
          <v-flex
            xs12
            text-center
          >
            <h2 class="font-weight-bold mt-4">
              Package Details
            </h2>
          </v-flex>
          <v-form>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <p>Total Value: {{ getCombinedValue(shipment?.items) }}</p>
                </v-col>
              </v-row>
              <v-row
                v-for="(item, index) in shipment?.items"
                :key="index"
                dense
              >
                <v-col cols="6">
                  <strong>Line {{ index + 1 }}</strong>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    color="error"
                    x-small
                    depressed
                    @click="removeItem(index)"
                  >
                    Remove
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-if="shipment.is_fba"
                    :ref="`asin_${index}`"
                    v-model="item.sku"
                    v-validate="'required'"
                    outlined
                    dense
                    :error-messages="errs.collect(`asin_${index}`)"
                    label="ASIN"
                    :data-vv-name="`asin_${index}`"
                    required
                  />
                  <v-text-field
                    v-else
                    :ref="`sku_${index}`"
                    v-model="item.sku"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`sku_${index}`)"
                    label="SKU"
                    :data-vv-name="`sku_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :ref="`description_${index}`"
                    v-model="item.description"
                    v-validate="'required|min:3'"
                    outlined
                    dense
                    :error-messages="errs.collect(`description_${index}`)"
                    label="Description"
                    :data-vv-name="`description_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.quantity"
                    v-validate="'required|min_value:1'"
                    outlined
                    dense
                    :error-messages="errs.collect(`quantity_${index}`)"
                    label="Quantity"
                    :data-vv-name="`quantity_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.value"
                    v-validate="'required|min_value:0.01'"
                    outlined
                    dense
                    :error-messages="errs.collect(`value_${index}`)"
                    label="Unit Value"
                    :data-vv-name="`value_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="item.currency"
                    v-validate="'required'"
                    :error-messages="errs.collect(`currency_${index}`)"
                    :data-vv-name="`currency_${index}`"
                    label="Currency"
                    :items="$currencyAsFlatArray()"
                    dense
                    outlined
                  />
                </v-col>
                <v-col
                  v-if="showCustomsForm(shipment.country_code, shipment.province_code)"
                  cols="12"
                >
                  <v-text-field
                    :ref="`customs_description_${index}`"
                    v-model="item.customs_description"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`customs_description_${index}`)"
                    label="Customs Description"
                    :data-vv-name="`customs_description_${index}`"
                    required
                  />
                </v-col>
            
                <v-col
                  v-if="showCustomsForm(shipment.country_code, shipment.province_code)"
                  cols="6"
                >
                  <v-text-field
                    v-model="item.hs_code"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`hs_code_${index}`)"
                    label="HS Code"
                    :data-vv-name="`hs_code_${index}`"
                    required
                  />
                </v-col>
                <v-col
                  v-if="showCustomsForm(shipment.country_code, shipment.province_code)"
                  cols="6"
                >
                  <v-autocomplete
                    v-model="item.country_of_origin"
                    :rules="[
                      () => isCountryOfOriginRequired(shipment.country_code) 
                        ? !!item.country_of_origin || 'Country of Origin is required for US shipments' 
                        : true
                    ]"
                    outlined
                    dense
                    :error-messages="errs.collect(`country_of_origin_${index}`)"
                    :data-vv-name="`country_of_origin_${index}`"
                    :items="countries"
                    label="Country of Origin"
                    item-text="name"
                    item-value="code"
                    dusk="sf_country"
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="mb-1 mt-0" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    small
                    depressed
                    @click="addItem"
                  >
                    Add Line
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-flex
                  xs12
                  text-center
                >
                  <h2 class="font-weight-bold mt-4">
                    Store Info
                  </h2>
                </v-flex>
                <v-col
                  cols="6"
                  class="pr-0"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="shipment.order_id"
                      label="Order ID (Optional)"
                    />
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          class="mt-3"
                          v-on="on"
                        >
                          <v-icon color="orange lighten-1">
                            mdi-information-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Enter the order ID from your store for this
                        shipment.</span>
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col
                  v-if="userStores.user_stores.length > 0"
                  cols="6"
                >
                  <v-select
                    v-model="shipment.user_store_id"
                    :items="userStores.user_stores"
                    label="Store (Optional)"
                    single-line
                    item-text="identifier"
                    item-value="id"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-layout
            align-center
            class="mt-4"
          >
            <v-flex
              xs12
              text-center
            >
              <v-btn
                dusk="sf_package_contents_next"
                large
                depressed
                color="primary"
                @click="updateShipment()"
              >
                Next
                <v-icon dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import CONSTANTS from '@/constants';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      items: [],
      errors: {},
      searchInput1: '',
      searchInput2: '',
      countries: CONSTANTS.COUNTRIES,
    };
  },
  computed: {
    ...mapState(['userStores']),
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        if (newValue == 3) {
          if (this.shipment.items.length == 0) {
            this.addItem();
          }
          // var self = this; //code before the pause

          // setTimeout(function () {
          //   self.$refs.description_0.focus();
          // }, 2000);
        }
      }
    }
  },
  mounted() {
    if (this.userStores.user_stores.length === 0) {
      this.$store.dispatch('userStores/getStores');
    }
  },
  created() {
    // window.addEventListener('keydown', (e) => {
    //   if (this.processing == 0 && this.step == 3 && e.key == 'Enter') {
    //     this.updateShipment();
    //   }
    // });
  },
  methods: {
    setStep() {
      this.$store.commit('singleFlow/previous');
    },
    updateShipment() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$http
            .put('/shipments/' + this.shipment.id, this.shipment)
            .then((res) => {
              this.shipment = res.data;
              this.$store.commit('singleFlow/next');
            })
            .catch(() => {});
        }
      });
    },
    removeItem(index) {
      this.shipment.items.splice(index, 1);
    },
    addItem() {
      this.shipment.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    }
  }
};
</script>
