<template>
  <StallionDialog
    v-model="show"
    :width="700"
    :disabled="disabled"
    title="Update Details"
  >
    <template #content>
      <v-form @submit.prevent="save">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h2>Package Contents</h2>
            </v-col>
          </v-row>

          <transition-group
            name="fade"
            tag="div"
          >
            <v-row
              v-for="(row, index) in rows"
              :key="index + 'a'"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="row.description"
                  v-validate="'required'"
                  :error-messages="errs.collect('item')"
                  label="Item"
                  data-vv-name="item"
                />
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="row.quantity"
                  v-validate="'required'"
                  :error-messages="errs.collect('quantity')"
                  label="Quantity"
                  data-vv-name="quantity"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="row.value"
                  v-validate="'required|min_value:0.00'"
                  :error-messages="errs.collect('value')"
                  label="Unit Value (USD)"
                  data-vv-name="value"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  v-if="index > 0"
                  text
                  icon
                  dark
                  color="red"
                  @click="remove(index)"
                >
                  <v-icon dark>
                    remove
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  text
                  icon
                  color="primary"
                  @click="add(index)"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </transition-group>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <h2>Supporting Document</h2>
            </v-col>

            <v-col cols="12">
              <v-file-input
                v-model="file"
                accept="image/png, image/jpeg, application/pdf"
                prepend-icon="mdi-file-outline"
                label="Supporting Document"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="12" md="12">
                <h2>Additional Photo(s) Request by Admin</h2>
              </v-col>

              <v-col cols="12">
                <v-menu v-if="this.returnShipment.admin_images_required">
                  <template #activator="{ on }">
                    <v-btn
                      class="ml-3"
                      large
                      color="primary"
                      v-on="on"
                      @click="$refs.uploadImages.openDialog()">
                      Add images
                    </v-btn>
                  </template>
                </v-menu>
                <upload-images
                  ref="uploadImages"
                  :shipment_return="this.returnShipment"
                ></upload-images>
              </v-col>-->
          </v-row>
        </v-container>

        <request-additional-photos
          ref="requestAdditionalPhotos"
          :return-shipment="returnShipment"
          outlined
        />
      </v-form>
    </template>

    <template #actions>
      <v-menu
        v-if="returnShipment"
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            v-if="returnShipment?.images_required"
            outlined
            color="primary"
            :disabled="loading"
            v-on="on"
            @click="$refs.requestAdditionalPhotos.openDialog()"
          >
            Photos Requested
          </v-btn>
          <v-btn
            v-else-if="returnShipment?.content_images?.length == 0"
            :disabled="loading"
            outlined
            color="primary"
            v-on="on"
            @click="$refs.requestAdditionalPhotos.openDialog()"
          >
            Request Images
          </v-btn>
          <v-btn
            v-else
            :disabled="loading"
            outlined
            color="primary"
            v-on="on"
            @click="$refs.requestAdditionalPhotos.openDialog()"
          >
            Content Photos
          </v-btn>
        </template>
      </v-menu>

      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      >
        <v-icon
          left
          small
        >
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    returnShipment: {
      type: Object,
      default: null
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      files: [],
      show: null,
      loading: null,
      file: null,
      rows: [
        {
          description: null,
          value: null,
          quantity: 1
        }
      ]
    };
  },

  methods: {
    add() {
      var defaultRow = {
        description: null,
        value: null,
        quantity: 1
      };
      this.rows.push(defaultRow);
    },
    remove(index) {
      this.rows.splice(index, 1);
    },
    submit: function() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;

          let formData = new FormData();

          if (this.file) {
            formData.append('file', this.file);
          }

          formData.append('items', JSON.stringify(this.rows));
          formData.append('return_id', this.returnShipment.id);

          this.$http
            .post('returns/provide-details', formData)
            .then(response => response.data)
            .then((response) => {
              if(response.success){
                Event.fire('update-returns');
                this.successMessage('Details added successfully');
                this.show = false;
              }else{
                this.errorMessage();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    openDialog(param) {
      this.show = true;
      this.returnShipment = param;
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
