// Admin
const AdminMain = () => import('../pages/admin/index.vue');

const Shipments = () => import('../pages/admin/shipments/index.vue');
const ShipmentsHistory = () => import('../pages/admin/shipments/history.vue');

// Returns
const ReturnsCreate = () => import('../pages/admin/returns/create.vue');
const ReturnsIndex = () => import('../pages/admin/returns/index.vue');

// Commissions
const CommissionStructuresIndex = () => import('../pages/admin/commission-structures/index.vue');
const CommissionStructureShow = () => import('../pages/admin/commission-structures/show.vue');
const CommissionPayoutsIndex = () => import('../pages/admin/commission-payouts/index.vue');

// Batches
const BatchesIndex = () => import('../pages/admin/batches/index.vue');

const ReturnsShow = () => import('../pages/admin/returns/show.vue');
const ReturnsOrder = () => import('../pages/admin/return-orders/index.vue');
const ReturnsOrdersView = () => import('../pages/admin/return-orders/view.vue');
const ReturnsHistory = () => import('../pages/admin/returns/history.vue');

// Users
const UsersIndex = () => import('../pages/admin/users/index.vue');
const UserShow = () => import('../pages/admin/users/show.vue');

// Other
const Export = () => import('../pages/admin/export/index.vue');
const ExportInbound = () => import('../pages/admin/export-inbound/index.vue');
const CustomPricing = () => import('../pages/admin/custom-pricing/index.vue');

const CustomPricingShow = () =>
  import('../pages/admin/custom-pricing/show.vue');
const Analytics = () => import('../pages/admin/analytics/index.vue');
const AnalyticsCarrier = () =>
  import('../pages/admin/analytics/analytics-carrier.vue');

const AdminScan = () => import('../pages/admin/scan/index.vue');

const DomesticReturnScan = () =>
  import('../pages/admin/returns/domestic-scan.vue');

const SortUser = () => import('../pages/admin/sort/sort-user.vue');
const SortFBA = () => import('../pages/admin/sort/fba.vue');

const AdminCloseout = () =>
  import('../pages/admin/admin_closeouts/closeout.vue');

const AdminCloseoutShow = () =>
  import('../pages/admin/admin_closeouts/show.vue');

const AdminCloseoutIndex = () =>
  import('../pages/admin/admin_closeouts/index.vue');

const SuppliesOrders = () => import('../pages/admin/supplies/orders.vue');
const SuppliesInventory = () => import('../pages/admin/supplies/inventory.vue');
const SuppliesScan = () => import('../pages/admin/supplies/scan.vue');

const SuppliesShow = () => import('../pages/admin/supplies/show.vue');

const PrintLabels = () => import('../pages/admin/printlabels/index.vue');

const ReturnTrips = () => import('../pages/admin/return-trips/index.vue');
const ReturnTripShow = () => import('../pages/admin/return-trips/show.vue');
const ReturnTripNew = () => import('../pages/admin/return-trips/new.vue');

const EmployeeLocation = () =>
  import('../pages/admin/employee-location/index.vue');

const UsScan = () => import('../pages/admin/us-employee/us-scan.vue');

const UsSortScan = () => import('../pages/admin/us-employee/sort-scan.vue');

const UsSort = () => import('../pages/admin/us-employee/sort.vue');

const DisposalScan = () =>
  import('../pages/admin/us-employee/disposal-scan.vue');

const WholesaleLabeling = () => import('../pages/admin/us-employee/wholesale-labeling.vue');

const ShipmentList = () =>
  import('../pages/admin/us-employee/shipment-list.vue');
const LabelsIndex = () => import('../pages/admin/us-employee/labels.vue');

const ReturnScan = () => import('../pages/admin/returns/scan.vue');

const ScanOrder = () => import('../pages/admin/returns/scan-order.vue');
const RackScan = () => import('../pages/admin/us-employee/rack-scan.vue');
const RackLabels = () => import('../pages/admin/rack-labels/index.vue');
const PostageAdjustments = () =>
  import('../pages/admin/postage-adjustments/index.vue');

// Return Lookup
const ReturnLookup = () => import('../pages/admin/return-lookups/Index.vue');

const OutboundTrips = () => import('../pages/admin/outbound-trips/index.vue');
const OutboundTripShow = () => import('../pages/admin/outbound-trips/show.vue');
const OutboundTripNew = () => import('../pages/admin/outbound-trips/new.vue');

const RecurringChargesIndex = () => import('../pages/admin/recurring-charges/index.vue');

const UserGroupIndex = () => import('../pages/admin/user-groups/index.vue');
const UserGroupShow = () => import('../pages/admin/user-groups/show.vue');

const RolesAndPermissionsIndex = () =>
  import('../pages/admin/roles-and-permissions/index.vue');

const SampleRatesIndex = () => import('../pages/admin/sample-rates/index.vue');

const VoidsIndex = () => import('../pages/admin/voids/index.vue');
const ClaimsIndex = () => import('../pages/admin/claims/index.vue');

const GenerateRatesIndex = () => import('../pages/admin/generate-rates/index.vue');

const ClaimsReview = () => import('../pages/admin/claims/review.vue');

const ClaimsNew = () => import('../pages/admin/claims/new.vue');

const ProviderCredentials = () =>
  import('../pages/admin/provider-credentials/index.vue');

const Announcements = () => import('../pages/admin/announcements/index.vue');
const SystemSettings = () => import('../pages/admin/system-settings/index.vue');

const Pickups = () => import('../pages/admin/pickups/index.vue')

const PalletLabels = () => import('../pages/admin/pallet-labels/index.vue');
const ReturnDeliveryRequired = () => import('../pages/admin/returns/delivery-required.vue');

const ManageContainers = () => import('../pages/admin/npi/manage-containers.vue');
const CreateTrip = () => import('../pages/admin/npi/create-trip.vue');
const routes = [
  {
    path: '/admin',
    name: 'admin-dashboard',
    meta: { permission: 'access.admin' },
    component: AdminMain
  },
  {
    path: '/admin/shipments/:filter?',
    name: 'admin-shipments',
    props: true,
    meta: { permission: 'shipments.view' },
    component: Shipments
  },
  {
    path: '/admin/shipment-history/:id/:shipcode',
    name: 'shipment-history',
    meta: { layout: 'admin-layout' },
    component: ShipmentsHistory
  },
  {
    path: '/admin/voids',
    name: 'admin-voids',
    component: VoidsIndex,
    meta: { reg: true, permission: 'voids.view' }
  },
  {
    path: '/admin/batches',
    name: 'admin-batches',
    component: BatchesIndex,
    meta: { reg: true, permission: 'batches.manage' }
  },
  {
    path: '/admin/claims/:id/review',
    name: 'admin-claims-review',
    component: ClaimsReview,
    meta: { reg: true, permission: 'claims.view' }
  },
  {
    path: '/admin/claims/new',
    name: 'admin-claims-new',
    component: ClaimsNew,
    meta: { reg: true, permission: 'claims.manage' }
  },
  {
    path: '/admin/claims',
    name: 'admin-claims-index',
    component: ClaimsIndex,
    meta: { reg: true, permission: 'claims.view' }
  },

  {
    path: '/admin/sample-rates',
    name: 'admin-sample-rates',
    props: true,
    meta: { permission: 'sample_rates.view' },
    component: SampleRatesIndex
  },

  {
    path: '/admin/generate-rates',
    name: 'admin-generate-rates',
    props: true,
    meta: { permission: 'sample_rates.create' },
    component: GenerateRatesIndex
  },

  {
    path: '/admin/employee-location',
    name: 'admin-employee-location',
    props: true,
    meta: { permission: 'branch.manage' },
    component: EmployeeLocation
  },
  {
    path: '/admin/return-trips/new',
    name: 'admin-return-trips-new',
    props: true,
    meta: { permission: 'trips.manage' },
    component: ReturnTripNew
  },
  {
    path: '/admin/return-trips/:tripNumber',
    name: 'admin-return-trip-show',
    props: true,
    meta: { permission: 'trips.manage' },
    component: ReturnTripShow
  },
  {
    path: '/admin/return-trips',
    name: 'admin-return-trips',
    // props: true,
    meta: { permission: 'trips.manage' },
    component: ReturnTrips
  },
  {
    path: '/admin/returns/scan',
    name: 'admin-returns-scan',
    props: true,
    meta: { permission: 'returns.scan' },
    component: ReturnScan
  },
  {
    path: '/admin/supplies-orders',
    name: 'admin-supplies-orders',
    meta: { permission: 'supplies.view' },
    component: SuppliesOrders
  },

  {
    path: '/admin/supplies-invoice/:id',
    name: 'admin-supplies-show',
    meta: { permission: 'supplies.view' },
    component: SuppliesShow
  },
  {
    path: '/admin/supplies-inventory',
    name: 'admin-supplies-inventory',
    meta: { permission: 'supplies.edit.inventory' },
    component: SuppliesInventory
  },
  {
    path: '/admin/supplies-scan/:status_id',
    name: 'admin-supplies-scan',
    meta: { permission: 'supplies.scan' },
    component: SuppliesScan
  },

  {
    path: '/admin/returns/scan-order',
    name: 'admin-return-scan-order',
    props: true,
    meta: { permission: 'returns.view' },
    component: ScanOrder
  },
  {
    path: '/admin/returns',
    name: 'admin-return-index',
    meta: { permission: 'returns.view' },
    component: ReturnsIndex
  },
  {
    path: '/admin/returns/delivery-required',
    name: 'return-delivery-required',
    props: true,
    meta: { permission: 'returns.view' },
    component: ReturnDeliveryRequired
  },
  {
    path: '/admin/returns/:id',
    name: 'admin-return-show',
    meta: { permission: 'returns.view' },
    component: ReturnsShow,
    props: true
  },
  {
    path: '/admin/returns-create',
    name: 'admin-return-create',
    meta: { permission: 'returns.edit' },
    component: ReturnsCreate
  },
  {
    path: '/admin/return-history/:id/:barcode',
    name: 'return-history',
    meta: { layout: 'admin-layout' },
    component: ReturnsHistory
  },
  {
    path: '/admin/analytics',
    name: 'admin-analytics',
    meta: { permission: 'analytics.view' },
    component: Analytics
  },
  {
    path: '/admin/analytics-carrier',
    name: 'admin-analytics-carrier',
    meta: { permission: 'analytics.view' },
    component: AnalyticsCarrier
  },
  {
    path: '/admin/orders',
    name: 'admin-return-orders',
    meta: { permission: 'returns.view' },
    component: ReturnsOrder
  },
  {
    path: '/admin/return-orders/:id',
    name: 'admin-return-orders-view',
    props: true,
    meta: { permission: 'returns.view' },
    component: ReturnsOrdersView
  },
  {
    path: '/admin/scan/:type',
    props: true,
    name: 'admin-scan',
    meta: { permission: 'shipments.scan' },
    component: AdminScan
  },

  {
    path: '/admin/scan-domestic',
    name: 'admin-scan-domestic-scan',
    meta: { permission: 'shipments.scan' },
    component: DomesticReturnScan
  },
  {
    path: '/admin/users',
    name: 'users-index',
    meta: { permission: 'users.view' },
    component: UsersIndex
  },
  {
    path: '/admin/users/:id',
    name: 'admin-user-show',
    meta: { permission: 'users.view' },
    component: UserShow
  },
  {
    path: '/admin/recurring-charges',
    name: 'recurring-charges-index',
    meta: { permission: 'recurring_charges.manage' },
    component: RecurringChargesIndex
  },
  {
    path: '/admin/export',
    name: 'admin-data-export',
    meta: { permission: 'shipments.view' },
    component: Export
  },
  {
    path: '/admin/export-inbound',
    name: 'admin-data-ex',
    meta: { permission: 'returns.view' },
    component: ExportInbound
  },
  {
    path: '/admin/custom-pricing',
    name: 'custom-pricing',
    meta: { permission: 'pricing.manage' },
    component: CustomPricing
  },
  {
    path: '/admin/custom-pricing/:user_id',
    name: 'custom-pricing-show',
    meta: { permission: 'pricing.manage' },
    component: CustomPricingShow
  },
  {
    path: '/admin/fba',
    name: 'admin-fba',
    meta: { permission: 'shipments.sort' },
    component: SortFBA
  },

  {
    path: '/admin/sort/:sortType',
    name: 'admin-apc',
    props: true,
    meta: { permission: 'shipments.sort' },
    component: SortUser
  },
  {
    path: '/admin/closeout/:type',
    name: 'admin-closeout',
    props: true,
    meta: { permission: 'closeouts.view' },
    component: AdminCloseout
  },

  {
    path: '/admin/closeouts',
    name: 'admin-closeouts',
    props: true,
    meta: { permission: 'closeouts.view' },
    component: AdminCloseoutIndex
  },
  {
    path: '/admin/closeouts/:id',
    name: 'admin-closeouts-show',
    props: true,
    meta: { permission: 'closeouts.view' },
    component: AdminCloseoutShow
  },

  {
    path: '/admin/printlabels',
    name: 'admin-print-labels',
    meta: { permission: 'access.us' },
    component: PrintLabels
  },

  // us_employee
  {
    path: '/admin/shipment-list',
    name: 'admin-shipment-list',
    meta: { permission: 'access.us' },
    component: ShipmentList
  },
  {
    path: '/admin/us-scan',
    name: 'admin-us-scan',
    meta: { permission: 'access.us' },
    component: UsScan
  },
  {
    path: '/admin/labels/returns',
    name: 'admin-return-labels',
    props: true,
    meta: { permission: 'access.us' },
    component: LabelsIndex
  },

  {
    path: '/admin/disposal-scan',
    name: 'admin-disposal-scan',
    meta: { permission: 'access.us' },
    component: DisposalScan
  },
  {
    path: '/admin/wholesale-labeling',
    name: 'admin-wholesale-labeling',
    meta: { permission: 'access.us' },
    component: WholesaleLabeling
  },
  {
    path: '/admin/rack-labels',
    name: 'admin-rack-labels',
    meta: { permission: 'access.us' },
    component: RackLabels
  },
  {
    path: '/admin/admin-rack-labels',
    name: 'admin2-rack-labels',
    meta: { permission: 'rack_label.view' },
    component: RackLabels
  },
  {
    path: '/admin/rack-scan',
    name: 'admin-rack-scan',
    meta: { permission: 'access.us' },
    component: RackScan
  },
  {
    path: '/admin/return-lookup',
    name: 'return-lookup',
    meta: { permission: 'returns.view' },
    component: ReturnLookup
  },
  {
    path: '/admin/outbound-trips/new',
    name: 'admin-outbound-trips-new',
    props: true,
    meta: { permission: 'trips.manage' },
    component: OutboundTripNew
  },
  {
    path: '/admin/outbound-trips/:tripNumber',
    name: 'admin-outbound-trip-show',
    props: true,
    meta: { permission: 'trips.manage' },
    component: OutboundTripShow
  },
  {
    path: '/admin/outbound-trips',
    name: 'admin-outbound-trips',
    // props: true,
    meta: { permission: 'trips.manage' },
    component: OutboundTrips
  },

  {
    path: '/admin/user-groups',
    name: 'admin-user-groups-index',
    props: true,
    meta: { permission: 'users.view' },
    component: UserGroupIndex
  },

  {
    path: '/admin/user-groups/:id',
    name: 'admin-user-groups-show',
    props: true,
    meta: { permission: 'users.view' },
    component: UserGroupShow
  },
  {
    path: '/admin/postage-adjustments',
    name: 'admin-postage-adjustments',
    props: true,
    meta: { permission: 'adjustments.manage' },
    component: PostageAdjustments
  },
  {
    path: '/admin/roles-and-permissions',
    name: 'admin-roles-and-permissions-index',
    props: true,
    meta: { permission: 'roles_and_permissions.manage' },
    component: RolesAndPermissionsIndex
  },
  {
    path: '/admin/provider-credentials',
    name: 'provider-credentials',
    meta: { permission: 'access.admin' },
    component: ProviderCredentials
  },
  {
    path: '/admin/dropoff-locations',
    name: 'admin-dropoff-locations',
    meta: { permission: 'dropoff_locations.edit' },
    component: () => import('../pages/admin/dropoff-locations/index.vue')
  },
  {
    path: '/admin/regions',
    name: 'admin-regions',
    meta: { permission: 'regions.edit' },
    component: () => import('../pages/admin/regions/index.vue')
  },
  {
    path: '/admin/postage-restrictions',
    name: 'admin-postage-restrictions',
    meta: { permission: 'postage.restrictions' },
    component: () => import('../pages/admin/postage-restrictions/index.vue')
  },
  {
    path: '/admin/announcements',
    name: 'admin-announcements',
    meta: { permission: 'announcements.manage' },
    component: Announcements
  },
  {
    path: '/admin/system-settings',
    name: 'admin-system-settings',
    meta: { permission: 'system_settings.manage' },
    component: SystemSettings
  },
  {
    path: '/admin/pickups',
    name: 'admin-pickups',
    meta: { permission: 'pickups.view' },
    component: Pickups
  },
  {
    path: '/admin/commission-structures',
    name: 'admin-commission-structures',
    component: CommissionStructuresIndex,
    meta: { reg: true, permission: 'commissions.manage' }
  },
  {
    path: '/admin/commission-structure/:id',
    name: 'admin-commission-structure-show',
    component: CommissionStructureShow,
    meta: { reg: true, permission: 'commissions.manage' }
  },
  {
    path: '/admin/commission-payouts',
    name: 'admin-commission-payouts',
    component: CommissionPayoutsIndex,
    meta: { reg: true, permission: 'commissions.manage' }
  },
  {
    path: '/admin/us-sort-scan',
    name: 'admin-us-sort-scan',
    meta: { permission: 'access.us' },
    component: UsSortScan
  },
  {
    path: '/admin/us-sort',
    name: 'admin-us-sort',
    meta: { permission: 'access.us' },
    component: UsSort
  },
  {
    path: '/admin/dropoff-invoices',
    name: 'admin-dropoff-invoices',
    meta: { permission: 'users.dropoff_invoices' },
    component: () => import('../pages/admin/users/DropOffInvoices.vue')
  },
  {
    path: '/admin/labels/pallets',
    name: 'admin-pallet-labels',
    meta: { permission: 'access.us' },
    component: PalletLabels
  },
  {
    path: '/admin/npi/containers',
    name: 'admin-manage-containers',
    meta: { permission: 'shipments.sort' },
    component: ManageContainers

  },
  {
    path: '/admin/npi/create-trip',
    name: 'admin-create-trip',
    meta: { permission: 'shipments.sort' },
    component: CreateTrip

  },
];

// set auth required and layout
for (var i = 0; i < routes.length; i++) {
  routes[i].meta = Object.assign(routes[i].meta, {
    auth: true,
    admin: true,
    layout: 'admin-layout'
  });
}

export default routes;
