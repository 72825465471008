<template>
  <v-container
    class="py-5 px-8"
    fluid
    style="background-color: #fcfcfc"
  >
    <v-container>
      <v-row>
        <v-col>
          <h1>CBSA CARM Profile Agreement</h1>
        </v-col>
      </v-row>

      <!-- Completed CARM Agreement Banner -->
      <v-row v-if="carm_agree_status">
        <v-col>
          <v-alert
            type="success"
            text
          >
            You have successfully completed your CARM profile agreement. Thank you!
          </v-alert>
        </v-col>
      </v-row>

      <!-- CBSA CARM Information Section -->
      <v-row>
        <v-col>
          <h3 class="pa-5">
            CBSA CARM System Launched in October 2024
          </h3>
          <v-card
            outlined
            class="pa-5"
          >
            <p>
              The Canada Border Services Agency (CBSA) is introducing the CARM (Assessment and Revenue Management) system in October 2024. This update will modernize duties and taxes collection for imported goods.
            </p>
            <p><strong>No changes in duties and taxes</strong></p>
            <p><strong>Action Required:</strong> Clients must create a CARM account to process returns to Canada.</p>
            <p>
              <strong>Get Started:</strong> Access the <a
                href="https://ccp-pcc.cbsa-asfc.cloud-nuage.canada.ca/en/onboarding-documentation"
                target="_blank"
              >CARM setup guide</a>
            </p>
            <p>
              <strong>More Info:</strong> <a
                href="https://www.cbsa-asfc.gc.ca/services/carm-gcra/schedule-calendrier-eng.html"
                target="_blank"
              >Visit the CBSA website</a>
            </p>
            <p>Sign up for your CARM account now to ensure smooth returns. Need help? Contact us anytime at +1 877-863-7447 or <a href="mailto:hello@stallionexpress.ca">hello@stallionexpress.ca</a>&nbsp;!</p>
            <v-btn
              color="primary"
              outlined
              href="https://www.cbsa-asfc.gc.ca/services/carm-gcra/menu-eng.html"
              target="_blank"
            >
              Learn More About CARM
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: "CARM Profile Agreement | Stallion Express"
  },
  data() {
    return {
      carm_agree_status: null, // Will hold the true/false value of 'agree'
    };
  },
  mounted() {
    // On component load, make a GET request to check CARM agreement status
    this.checkCarmAgreement();
  },
  methods: {
    checkCarmAgreement() {
      // GET request to /returns/carm-agree
      this.$http
        .get('/returns/carm-agree')
        .then((res) => {
          if (res.data.success) {
            this.carm_agree_status = res.data.agree;
          } else {
            this.carm_agree_status = false; // Handle unexpected responses
          }
        })
        .catch(() => {
          this.carm_agree_status = false; // Handle errors
        });
    }
  }
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .header-container {
    display: block;
  }
}
</style>
