<template>
  <FullPageLayout title="Wholesale Scan">
    <template #default>
      <v-layout wrap>
        <v-flex xs12>
          <v-form>
            <v-container>
              <v-row
                class="pb-2"
                style="justify-content: space-between;"
              >
                <v-text-field
                  v-model="barcode"
                  label="Manual Entry"
                  class="mr-2"
                  outlined
                  clearable
                  dense
                  hide-details
                  @keydown.enter.prevent="manualScan"
                />

                <v-btn
                  color="primary"
                  @click="manualScan"
                >
                  Submit
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-flex>
        <v-flex
          id="scan-page"
          xs12
        >
          <v-flex
            v-for="(item, index) in errors"
            :key="index"
            xs12
          >
            <v-alert
              class="pa-2"
              :value="true"
              dismissible
              type="error"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>
          </v-flex>
          <v-flex
            v-for="(item, index) in warnings"
            :key="index"
            xs12
          >
            <v-alert
              class="pa-2"
              :value="true"
              dismissible
              type="warning"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>
          </v-flex>


          <v-data-table
            class="table-striped"
            :headers="headers"
            :items="shipments"
            :loading="loading"
            no-data-text="Awaiting scanned items"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
          >
            <template #item.current_location="{ item }">
              <span
                v-html="$options.filters.location(item.current_location_id)"
              />
            </template>

            <template #item.destination_location="{ item }">
              <span
                v-html="$options.filters.location(item.destination_location_id)"
              />
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>
            <!-- <template v-slot:item.tracking="{ item }">
                <div v-if="item.tracking_events.length > 0">{{ item.tracking_events[0].status }}</div>
              </template>-->
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      shipment: {},
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,
      selected: [],
      headers: [
        {
          text: 'Barcode',
          sortable: false,
          value: 'barcode'
        },
        {
          text: 'User ID',
          sortable: false,
          value: 'user_id'
        },

        {
          text: 'Current Location',
          sortable: false,
          value: 'current_location'
        },

        {
          text: 'Destination Location',
          sortable: false,
          value: 'destination_location'
        },

        {
          text: 'Created At',
          sortable: false,
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 200,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: []
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {},
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      // let self = this;

      this.scan(barcode).then(res => {
        this.shipment = res.shipment;
        this.shipments.push(res.shipment);

        this.printUrl(res.label_url);
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
            .post('/admin/scan/wholesale', { code: barcode })
            .then(response => response.data)
            .then(response => {
              if (response.success == true) {
                this.playSuccessSound();
                resolve({
                  shipment: response.shipment,
                  label_url: response.label_url
                });
              } else {
                this.playErrorSound();
                this.errors.push(response.error);
                reject({});
              }
            })
            .catch(err => {
              this.playErrorSound();
              console.log(err.response);
              this.errors.push('Server error');
              reject({});
            });
      });
    }
  }
};
</script>
